import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import {
  withDimensionsCSS,
  WithDimensionsProps,
  withPositionCSS,
  WithPositionProps,
} from 'app/shared/components/styleUtils';
import {
  withBorderCSS,
  WithBorderProps,
  withCursorCSS,
  WithCursorProps,
  withFlexCSS,
  WithFlexProps,
  withMaxPageWidthCSS,
  WithMaxPageWidthProps,
  withSpacingCSS,
  WithSpacingProps,
  withZIndexCSS,
  WithZIndexProps,
} from 'app/shared/components/styleUtils';

export interface BoxProps
  extends React.ComponentPropsWithoutRef<'div'>,
    WithSpacingProps,
    WithMaxPageWidthProps,
    WithFlexProps,
    WithBorderProps,
    WithPositionProps,
    WithCursorProps,
    WithZIndexProps,
    WithDimensionsProps {
  backgroundColor?: string;
  backgroundImage?: string | string[];
  hoverBackgroundColor?: string;
  boxShadow?: string;
  display?: string;
  visibility?: string;
  marginInline?: string;
  backgroundPosition?: string;
  transition?: string;
  animation?: FlattenSimpleInterpolation;
  listStyle?: string;
}

export const Box = styled.div<BoxProps>`
  ${({
    theme,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    hoverBackgroundColor,
    boxShadow,
    display,
    visibility,
    marginInline,
    transition = 'background-color 400ms',
    animation,
    listStyle = 'none',
  }) => css`
    ${backgroundPosition && `background-position: ${backgroundPosition};`}
    ${backgroundColor && `background: ${backgroundColor};`}
    ${hoverBackgroundColor &&
      `&:hover {
        background-color: ${hoverBackgroundColor};
      }`}
    transition: ${transition};
    ${boxShadow && `box-shadow: ${boxShadow};`}
    ${display && `display: ${display};`}
    ${visibility && `visibility: ${visibility};`}
    ${marginInline && `margin-inline: ${marginInline};`}
    ${animation &&
      css`
        animation: ${animation};
      `}
    list-style: ${listStyle};
    ${theme.media.mobile`
      ${backgroundImage &&
        Array.isArray(backgroundImage) &&
        `
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${backgroundImage[0]});
      `}
    `}

    ${theme.media.tablet`
      ${backgroundImage &&
        Array.isArray(backgroundImage) &&
        `
        background-image: url(${backgroundImage[1]});
      `}
    `}

    ${theme.media.smallDesktop`
      ${backgroundImage &&
        Array.isArray(backgroundImage) &&
        `
        background-image: url(${backgroundImage[2]});
      `}
    `}

    ${theme.media.largeDesktop`
      ${backgroundImage &&
        Array.isArray(backgroundImage) &&
        `
        background-image: url(${backgroundImage[3]});
      `}
    `}

    ${backgroundImage &&
      !Array.isArray(backgroundImage) &&
      `background-image: url(${backgroundImage}); background-repeat: no-repeat; background-size: cover;`}
  `}

  ${withMaxPageWidthCSS};
  ${withFlexCSS};
  ${withSpacingCSS};
  ${withBorderCSS};
  ${withDimensionsCSS};
  ${withPositionCSS};
  ${withCursorCSS}
  ${withZIndexCSS};
`;
