import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import {
  withDimensionsCSS,
  WithDimensionsProps,
} from 'app/shared/components/styleUtils';

interface ExpandableAreaProps extends WithDimensionsProps {
  transitionSpeed: number;
  collapsedHeight: string;
}

const ExpandableArea = styled.div<ExpandableAreaProps>`
  ${({ transitionSpeed, collapsedHeight }) => css`
    ${withDimensionsCSS};

    overflow: hidden;
    transition: ease-in-out ${transitionSpeed}ms;
    height: ${collapsedHeight};
  `}
`;

interface ExpandableProps extends WithDimensionsProps {
  isExpanded: boolean;
  transitionSpeed?: number;
  collapsedHeight?: string;
}

export const Expandable: React.FC<ExpandableProps> = ({
  isExpanded,
  transitionSpeed = 500,
  collapsedHeight = '0px',
  children,
  ...props
}) => {
  const expandableAreaRef = useRef<HTMLDivElement>(null);

  const expandedHeight = `${expandableAreaRef.current?.scrollHeight}px`;
  const refStyle =
    isExpanded && !!expandableAreaRef.current
      ? { height: expandableAreaRef.current.style.height = expandedHeight }
      : { height: collapsedHeight };

  return (
    <ExpandableArea
      ref={expandableAreaRef}
      area-expanded={isExpanded}
      style={refStyle}
      transitionSpeed={transitionSpeed}
      collapsedHeight={collapsedHeight}
      {...props}
    >
      {children}
    </ExpandableArea>
  );
};
