import { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';

export interface WithDimensionsProps {
  h?: string;
  w?: string;
  maxHeight?: string;
  maxWidth?: string;
  minHeight?: string;
  minWidth?: string;
  overflow?: string;
  overflowX?: string;
  overflowY?: string;
  aspectRatio?: string;
  resize?: string;
  fullWidth?: boolean;
}

interface WithDimensionsCSSProps extends WithDimensionsProps {
  theme: CommonTheme;
}

export const withDimensionsCSS = ({
  h,
  w,
  fullWidth,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  overflow,
  overflowX,
  overflowY,
  aspectRatio,
  resize,
}: WithDimensionsCSSProps) => css`
  ${h && `height: ${h};`}
  ${!fullWidth && w && `width: ${w};`}
  ${!w && fullWidth && 'width: 100%;'}

  ${maxHeight && `max-height: ${maxHeight};`}
  ${maxWidth && `max-width: ${maxWidth};`}
  ${minHeight && `min-height: ${minHeight};`}
  ${minWidth && `min-width: ${minWidth};`}

  ${overflow && !overflowX && !overflowY && `overflow: ${overflow};`}
  ${overflowX && `overflow-x: ${overflowX};`}
  ${overflowY && `overflow-y: ${overflowY};`}

  ${aspectRatio && `aspectRatio: ${aspectRatio};`}
  ${resize && `resize: ${resize};`}
`;
