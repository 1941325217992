import styled from 'styled-components';

import {
  withDimensionsCSS,
  WithDimensionsProps,
} from 'app/shared/components/styleUtils';
import {
  withFontCSS,
  WithFontProps,
  withInputStyleCSS,
  WithInputStyleProps,
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';

export interface TextareaProps
  extends React.ComponentPropsWithRef<'textarea'>,
    WithInputStyleProps,
    WithSpacingProps,
    WithFontProps,
    WithDimensionsProps {}

export const Textarea = styled.textarea<TextareaProps>`
  ${withInputStyleCSS};
  ${withSpacingCSS};
  ${withFontCSS};
  ${withDimensionsCSS};
`;
