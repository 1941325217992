import React from 'react';
import styled from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import { useLinkClickBehavior } from 'app/shared/utils/useLinkClickBehavior';
import {
  withDimensionsCSS,
  WithDimensionsProps,
} from 'app/shared/components/styleUtils';
import {
  withFlexCSS,
  WithFlexProps,
  withFontCSS,
  WithFontProps,
  withLinkStyleCSS,
  WithLinkStyleProps,
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';

export interface LinkProps
  extends React.ComponentPropsWithoutRef<'a'>,
    WithSpacingProps,
    WithFlexProps,
    WithDimensionsProps,
    WithFontProps,
    React.AriaAttributes {
  openInNewTab?: boolean;
  display?: string;
}

interface LinkCSSProps extends LinkProps, WithLinkStyleProps {
  theme: CommonTheme;
}

// Keep withFlexCSS after withLinkStyleCSS
const StyledAnchor = styled.a<LinkCSSProps>`
  ${withDimensionsCSS};
  ${withSpacingCSS};
  ${withFontCSS};
  ${withLinkStyleCSS};
  ${withFlexCSS};
`;

export const Link: React.FC<LinkProps & WithLinkStyleProps> = ({
  openInNewTab = false,
  children,
  href,
  onClick,
  target,
  rel,
  ...props
}) => {
  const { anchorRef, handleClick, newRel, newTarget } = useLinkClickBehavior({
    openInNewTab,
    href,
    onClick,
    target,
    rel,
  });

  return (
    <StyledAnchor
      textDecoration="underline"
      textTransform="none"
      {...props}
      as="a"
      ref={anchorRef}
      onClick={handleClick}
      rel={newRel}
      target={newTarget}
      href={href}
    >
      {children}
    </StyledAnchor>
  );
};
